'use client';

import { Inter } from 'next/font/google';

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
  weight: ['400', '500', '600', '700', '800'],
  preload: true,
});

export default function ErrorPage({ error }: { error: Error & { digest?: string } }) {
  return (
    <div className="relative w-full flex justify-center items-center flex-col">
      <div className="relative flex flex-col justify-center items-center w-1/2">
        <div className="relative flex justify-center items-center text-center flex-col mt-6">
          <h2 className="text-3xl font-bold">Ops! Algo deu errado.</h2>
          <p className="mt-6">Desculpe, não foi possível carregar a página solicitada!</p>
          <a className="rounded-full p-2 px-8 bg-primary text-gray-50 mt-6" href="/">
            Voltar ao Início
          </a>
        </div>
      </div>
    </div>
  );
}
